<template>
  <div>
    <p>
      When it comes to LEGO building, SNOT doesn’t drip from your nose! It stands for “<b>S</b>tuds <b>N</b>ot (only)<b>O</b>n <b>T</b>op,” and the goal of “snotting” is to get the round pokey parts of LEGO pieces (the “studs”) pointing in more than one direction.
    </p>
    <p>
      In this two-part series, you’ll first discover a bit about the beginnings of SNOT building. In part two, you’ll pick up a few tips that could come in handy when you’re playing in LEGO Universe!
    </p>
    <p>
      Today there are lots of SNOT tricks that can help you construct LEGO models with awesome angles and jaw-dropping details, but for much of the last century LEGO pieces were made mostly for stacking.
    </p>
    <p>
      See how almost all the elements are simply layered up in this Rocket Base from 1973?
    </p>
    <!-- picED3D9FF4103DAC75575E8BB94FD4A04B.jpg -->
    <img src="">
    <p>
      An entirely new era of building began in the late 1970’s. Models became more detailed as new LEGO elements were introduced, including pieces designed especially for snotting.
    </p>
    <p>
      Just look at the difference in detail between the set from 1973 and this LEGO Space Cruiser from 1978*!
    </p>
    <!-- pic552E31014816C4004B829776E2ED82E3.jpg -->
    <img src="">

    <p>
      The Space Cruiser set included one of the very first LEGO elements made just for getting the studs pointing in more than one direction. That SNOT element was a bracket used for attaching thrusters to the rear of the retro spaceship.
    </p>
    <!-- pic75FAAFCB70C60B318A2F0417EE3961F9.jpg -->
    <img src="">

    <p>
      LEGO designers have added many more SNOT elements since the late 70’s, which is good news for all you fans looking forward to bringing alive crazy creations in LEGO Universe!
    </p>
    <p>
      Check out this MX-41 Switch Fighter to see how models have evolved with SNOT building!
    </p>
    <!-- pic6575C59C7B8196C95F22320B7EBA0E55.jpg -->
    <img src="">
    <p>
      Stay tuned for part two of 21<sup>st</sup> century SNOT! You’ll discover some space-saving SNOT techniques you can use to build models for LEGO Universe!
    </p>
    <hr>
    <p>
      <i><font size="1">*1978 also marked the introduction of the modern LEGO minifigure. Little did fans know at the time that someday they would be able bring alive their own custom minifigures in an incredible virtual world!</font></i>
    </p>
  </div>
</template>
